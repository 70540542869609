.ui-box-video {
    margin-bottom: 50px;
}

.ui-box-video .banner {
    display: block;
}

.ui-box-video .headline-border-holder {
    margin: 0 0 30px;
    text-transform: uppercase;
}

.ui-box-video .v-icon-play-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--common-white);
    z-index: 20;
}

.ui-box-video .v-icon-play-video::before {
    font-size: calc(40px*(16/11)); /* 16:11 ratio of icon */
    opacity: .8;
    transition: opacity .2s;
}

.ui-box-video .v-icon-play-video:hover::before {
    opacity: 1;
}

.ui-box-video .image {
    position: relative;
}

.ui-box-video .ios-wrapper {
    opacity: 0;
}

.ui-box-video .ios-wrapper ~ .ytp-controls-bar {
    visibility: hidden;
}

.ui-box-video iframe,
.ui-box-video .video,
.ui-box-video .video a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
}

.ui-box-video iframe {
    pointer-events: none;
}

.ui-box-video .ui-yt-player iframe,
.ui-box-video .ui-yk-player iframe {
    pointer-events: all !important;
}

.ui-box-video .ytp-pause-overlay-controls-hidden.paused-mode .ytp-pause-overlay {
    display: none !important;
}

.ui-box-video .ui-box-video-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
    z-index: 10;
}

/* Breakpoint 2 */
@media screen and (min-width: 480px) and (max-width: 767px) {
    .ui-box-video .v-icon-play-video::before {
        font-size: calc(70px*(16/11)); /* 16:11 ratio of icon */
    }
}

/* Breakpoint 3+ */
@media screen and (min-width: 768px) {
    .ui-box-video .v-icon-play-video::before {
        font-size: calc(100px*(16/11)); /* 16:11 ratio of icon */
    }
}

